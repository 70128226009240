/* Container to hold the icon bar and sidebar */
.icon-bar-container {
    position: relative;
  }
  
  /* Icon bar styling */
  .icon-bar {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 50px; /* Fixed width for the icon bar */
    background-color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    z-index: 1000;
    border-left: 1px solid #444;
  }
  
  .icon {
    margin: 20px 0;
    color: white;
    cursor: pointer;
  }
  
  .icon:hover {
    color: #00aaff;
  }
  
  /* Sidebar window */
  .sidebar-window {
    position: fixed;
    top: 0;
    right: 50px; /* Positioned right next to the icon bar */
    height: 100vh;
    width: 300px; /* Width of the sidebar window */
    background-color: #444;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 999;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  }
  
  .sidebar-window.open {
    transform: translateX(0); /* Slide in when open */
  }
  
  .sidebar-window.closed {
    transform: translateX(100%); /* Slide out when closed */
  }
  
  /* Sidebar content */
  .sidebar-content {
    padding: 20px;
    color: white;
  }
  
  .sidebar-content h4 {
    margin-top: 0;
  }
  