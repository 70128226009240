@tailwind base;
@tailwind components;
@tailwind utilities;
.search-component {
    position: fixed;
    top: 0;
    left: 250;
    width: 100%;
    z-index: 9999; /* Top bar should be highest */
  }
/* 🔹 Dropdown Container */
.dropdown-container {
    position: relative;
  }
  .search-right {
    display: flex;
    align-items: center;
    /* DON'T use margin-left here */
  }
  /* 🔹 Dropdown Toggle Button */
  .dropdown-toggle {
    background: #3a3a3a;
    color: white;
    border: 1px solid #555;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .chart-dropdown {
    position: relative; /* Ensure positioning is relative to this div */
    display: inline-block;
}
  /* 🔹 Dropdown Menu */
  .dropdown-menu {
    position: absolute;
    top: 100%; /* Aligns below button */
    left: 0;
    background-color: #2c2c2c;
    border-radius: 5px;
    padding: 8px;
    min-width: 160px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1200;  /* 🔥 Ensure it's above the left drawer */
}
  /* 🔹 Dropdown Items */
.dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  color: white; /* Default text color */
}

.dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Light hover effect */
  border-radius: 5px;
}

/* ✅ Change checkbox colors */
.dropdown-item input[type="checkbox"] {
  appearance: none; /* Hide default checkbox */
  width: 16px;
  height: 16px;
  border: 2px solid white; /* White border */
  border-radius: 4px;
  display: inline-block;
  position: relative;
}

/* ✅ When checked, make the checkbox fully white */
.dropdown-item input[type="checkbox"]:checked {
  background-color: white;
  border-color: white;
}

/* ✅ Add a checkmark */
.dropdown-item input[type="checkbox"]:checked::after {
  content:"V";
  font-size: 12px;
  color: black;
  position: absolute;
  top: 1px;
  left: 3px;
}
  /* 🔹 Checkbox Styling */
  .checkbox {
    margin-right: 8px;
  }
/* Left side */
.search-left {
    display: flex;
    align-items: center;
    gap: 15px; /* Space between items */
}

/* Right side */
.search-right {
    display: flex;
    align-items: center;
 
}
.search-icon-button {
    background: none;
    border: none;
    color: #0073e6;
    font-size: 18px;
    cursor: pointer;
    padding: 5px;
}

.current-symbol {
    margin-left: 10px;
    font-size: 16px;
    color: #333;
    white-space: nowrap; /* Prevents line break */
    overflow: hidden; /* Prevents overflow text */
    text-overflow: ellipsis; /* Adds "..." if text is too long */
    display: flex;
    align-items: center;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #1e1e1e;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 600px;
    max-height: 600px;
    overflow-y: auto;
    position: relative;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.close-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #fff;
}

.category-filters {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.category-button {
    
    background: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.category-button.active {
    background: #0073e6;
}

.search-bar {
    margin-bottom: 10px;
    padding: 5px 15px 5px 2px;
}

.search-bar input {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 4px;
    background-color: #333;
    color: #fff;
}

.suggestions-list {
    max-height: 400px;
    overflow-y: auto;
}

.suggestion-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #444;
    cursor: pointer;
    font-size: 14px;
}

.suggestion-item:hover {
    background-color: #333;
}

.ticker {
    color: #00aaff;
}

.name, .category, .exchange {
    margin-left: 10px;
}
.logo-container {
    display: flex;
    align-items: center;
    margin-left: 8px; /* Adjust space between text and logo */
}

.logo {
    height: 40px; /* Adjust logo size if needed */
    width: auto;
}
/* Light Mode Scrollbar */
::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #e5e7eb; /* Light Gray */
  }
  
  ::-webkit-scrollbar-thumb {
    background: #6b7280; /* Dark Gray */
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #4b5563; /* Darker Gray */
  }
  
  /* Dark Mode Scrollbar */
  .dark ::-webkit-scrollbar-track {
    background: #1f2937; /* Dark Gray */
  }
  
  .dark ::-webkit-scrollbar-thumb {
    background: #9ca3af; /* Light Gray */
  }
  
  .dark ::-webkit-scrollbar-thumb:hover {
    background: #6b7280; /* Darker Gray */
  }
  .w-full {
    min-width: 180px;
    text-align: left;
  }
  .absolute {
    top: 100%;
    left: 0;
    min-width: 180px;
  }
  .relative {
  position: relative;
}