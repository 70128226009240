/* General App Styles */
body, html , #root{
  background-color: #121212; /* A very dark grey, almost black */
  color: #e0e0e0; /* A light grey that is easy on the eyes */
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif; /* Ensures the font is consistent */
  height: 100%; /* Ensure the html and body take up full height */
}
/* General App Styles */
.App {
  text-align: center;
  height: 100%; 
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #333; /* Slightly lighter than the body for subtle contrast */
  color: white; /* Ensure text is white for readability */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link, a {
  color: #1e90ff; /* A brighter blue for better visibility */
  text-decoration: none; /* Optional style choice */
}

.App-link:hover, a:hover {
  text-decoration: underline; 
  /* Adds underline on hover for better interaction visibility */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.data-container {
  background-color: #121212;
  display: flex;
  flex-direction: row;
  flex: 1;
  z-index: 100; 
}

@media (max-width: 600px) {
  .data-container {
    flex-direction: column;
    z-index:100 ;
  }
}


.chart-tabs button {
  margin-right: 10px;
}

.vertical-charts {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  height: 300px; /* Adjust height as needed */
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

 


.chart-container1:last-child {
  /*margin-right: 0;*/
}
.chart-info-container {
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.chart-info-container div {
  color: white;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
}


/* Chart and InfoPanel Container */
.chart-container {
  border: 2px solid gray;
  border-radius: 5px;
  width: 100%;
  height: calc((100vh - 700px)*0.5);
  /*height: calc(100vh - 500px); */
  /* Adjust height as needed */
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden; /* Hide overflow to prevent scrollbars */
  transition: margin-left 0.3s ease, margin-right 0.3s ease;
}
.chart-tabs {
  display: flex;
  justify-content: left;
  margin-bottom: 5px; /* Adjust margin as needed */
  padding: 10px;
}
.chart-container1 {
  background-color: #222;
  border: 2px solid gray;
  border-radius: 5px;
  width: 100%;
  /*padding-top: 20px;*/
  height: calc((112vh - 100px)*0.5);
 /*  height: calc(130vh - 1000px);*/
  /* margin-bottom: 20px; Space between charts */
  display: flex;
  flex: 1;
  flex-direction: column;
  /* Padding inside the container */
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
 /* max-height: calc(100vh - 200px); Hide vertical scrolling */
 transition: margin-left 0.3s ease, margin-right 0.3s ease;
}
/* InfoPanel Styles */

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #444;
  position: relative; /* You can adjust the color to fit your design */
  z-index:100 ;
}

.search-bar {
  flex-grow: 1;
  z-index:100; 
}

.info-panel {
  
  font-size: 2vw;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  background-color: rgba(50, 50, 50, 0.8);
  color: white;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  text-align: left;
  z-index: 100;
  align-items: flex-start; /* Align text to the start (left) */
  justify-content: flex-start;
  width: 100%; 
  min-width: 0; /* Allow shrinking */
  max-width: 100%; /* Prevent expanding beyond the container */  
}
.info-panel1 {
  font-size: 2vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  background-color: rgba(50, 50, 50, 0.8);
  color: rgb(25, 53, 182);
  text-align: left;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 0; /* Allow shrinking */
  max-width: 100%; /* Prevent expanding beyond the container */
}
.hidden-text {
    visibility: hidden;
    position: absolute;
    white-space: nowrap;
}

/* Scrolling Text Animation for InfoPanel */
.scrolling-text {
  animation: scroll-left 20s linear infinite;
}

@keyframes scroll-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

/* ChartComponent Styles */
.chart-component {
  width: 100%;
  height: calc(100% - 30px);
  position: relative;
  top: 30px;
  z-index:100 ;
}


.data-container {
  background-color: #121212;
  z-index: 100; 
    /* Same dark background */
}
.info-panel {
  min-height: 30px;  
  height: auto;  
  z-index:100 ;
}
body, html {
  background-color: #121212;
  color: #e0e0e0;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  overflow: hidden; /* Prevent vertical scrolling */
  height: 100%; /* Prevents horizontal scroll */
}




.collapsible-container {
  position: absolute;
  top: 0;  /* Align at the top */
  left: 0;  /* Align at the left */
  z-index: 1000;
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent; /* Make sure the container has no background */
}

.toggle-button {
  background-color: transparent; /* Remove background color */
  color: white;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.panel-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  padding: 5px;
  margin: 0;
  border: none;
}
.panel-content1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  padding: 5px;
  margin: 10;
  border: none;
}

{/* 
.loading-spinner {
  border: 16px solid #f3f3f3; 
  border-top: 16px solid #3498db; 
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; 
}
*/}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.section-border {
  border: 1px solid #888; /* Light grey border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px; /* Space inside the section */
  margin-bottom: 15px; /* Space between sections */
  background-color: #444; /* Slightly lighter background */
}

.section-border p {
  margin: 0;
  color: #ccc; /* Light grey text */
}

/* General Container */
.app-container {
  background-color: #1d1d1d;
  color: #fff;
  display: flex;
  height: 100vh;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

/* Main Content Area */
.main-content {
  flex-grow: 1;
  
  margin-top: 40px;

  
  overflow: hidden;
  transition: margin-left 0.3s ease, margin-right 0.3s ease;
}
/*
.right-sidebar {
  width: 300px;
  background-color: #333;
  color: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1000;
  border-left: 2px solid gray; 
  height: 100vh;
  overflow: hidden;
  transition: transform 0.3s ease;
  
}
*/
.right-sidebar.open {
  width: 250px;
  transform: translateX(0);
  
}

.right-sidebar.closed {
  width: 0; /* Set width to 0 when closed */
  padding: 0;
  overflow: hidden;
  border-left: none;
  transform: translateX(100%);
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebar-section {
  flex: 1;
  margin-bottom: 10px;
  border: 2px solid gray; /* Border for individual sections */
  padding: 10px;
  overflow-y: auto; /* Scrollbar for each section */
}
.sidebar-section:last-child {
  margin-bottom: 0;
}
.sidebar-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  z-index: 1100;
  
}
/* Sidebar open button outside the sidebar */
.sidebar-toggle-open {

  
  background: #333; /* Set to match sidebar background */
  border: none;
  color: white;
  cursor: pointer;
  z-index: 1100;
  padding: 10px;
  border-radius: 5px;

}
.sidebar-content {
  padding: 5px;
}

.divider {
  border: 0;
  height: 1px;
  background: #444;
  margin: 20px 0;
}

/* Left Sidebar */
.left-sidebar {
  width: 300px; /* Width when open */
  background-color: #333;
  color: #fff;
  padding: 15px;
  position: fixed;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1000;
  transition: transform 0.3s ease;
  border-right: 2px solid gray;
  transform: translateX(0); /* Visible by default */
}
/*
.right-sidebar {
  width: 300px;
  background-color: #333;
  color: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1000;
  border-left: 2px solid gray; 
  height: 100vh;
  overflow: hidden;
  transition: transform 0.3s ease;
  
}
*/
.left-sidebar.closed {

  transform: translateX(-100%);
}

.left-sidebar + .main-content {
  margin-left: 330px; /* Adjust this based on your sidebar width */
}

.left-sidebar.closed + .main-content {
  margin-left: 0; /* When closed, the margin is removed */
}


/* Styling for the toggle button */
.sidebar-toggle-left {
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  z-index: 1100;
    padding: 10px;
  border-radius: 5px;
}

/* Left Sidebar Toggle Button */
.left-sidebar-toggle-open {
  position: fixed;
  top: 50%; /* Position in the middle of the left side */
  left: 0; /* Align to the left edge */
  transform: translateY(-50%); /* Center vertically */
  background: #333; /* Set to match the right sidebar background */
  border: none;
  color: white;
  cursor: pointer;
  z-index: 1100;
  padding: 10px;
  border-radius: 0 5px 5px 0; /* Rounded corners on the right side */
}

/* Remove the vertical line */
.sidebar-toggle-left-open::before {
  display: none;
}
.sidebar-toggle-left-open {
  position: fixed;
  top: 0;
  left: 0;
  width: 20px; /* Adjust the width as needed */
  height: 100vh; /* Full height */
  background: #333; /* Match the right sidebar background */
  border: none;
  color: white;
  cursor: pointer;
  z-index: 1100;
  padding: 0; /* Remove padding */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 5px 0; /* Rounded corners on the right side */
}


.left-sidebar.closed + .main-content {
  margin-left: 0; /* Reset margin when sidebar is closed */
}

/* WebKit browsers */
.sidebar-section::-webkit-scrollbar {
  width: 12px;
}

.sidebar-section::-webkit-scrollbar-track {
  background: #333; /* Background color for the track */
}

.sidebar-section::-webkit-scrollbar-thumb {
  background-color: gray; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
  border: 3px solid #333; /* Adds a border around the thumb to give it a padding effect */
}


@tailwind base;
@tailwind components;
@tailwind utilities;

/* Then your custom layer */
@layer utilities {
  .scrollbar-custom::-webkit-scrollbar {
    width: 12px;
  }

  .scrollbar-custom::-webkit-scrollbar-track {
    background: #e5e7eb; /* light mode: gray-200 */
  }

  .scrollbar-custom::-webkit-scrollbar-thumb {
    background-color: #9ca3af; /* light: gray-400 */
    border-radius: 10px;
    border: 3px solid #e5e7eb;
  }

  .dark .scrollbar-custom::-webkit-scrollbar-track {
    background: #1f2937; /* dark mode: gray-800 */
  }

  .dark .scrollbar-custom::-webkit-scrollbar-thumb {
    background-color: #6b7280; /* dark: gray-500 */
    border: 3px solid #1f2937;
  }
}








/* Left Sidebar styles */
.sidebar-left {
  position: fixed;
  top: 0;
  
  height: 100%;
  background-color: #333;
  transition: transform 0.3s ease-in-out;
  z-index: 9000;
  padding: 20px;
}
.sidebar-left {
  left: -250px; /* Start offscreen */
}
/* Class to make the left sidebar visible */
.sidebar-left.open {
  transform: translateX(250px); /* Slide in */
}
/* Close button styles */
.close-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
 
}
.sidebar {
  position: fixed;
  top: 0;
  right: -250px; /* Start offscreen */
  width: 250px;
  height: 100%;
  background-color: #333;
  transition: right 0.3s ease-in-out;
  z-index: 1000; /* High z-index to overlay other content */
  padding: 20px;
}

.sidebar.open {
  right: 0; /* Slide in */
}
.formula {
  background: #121212;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
  display: inline-block;
}
.term {
  font-weight: bold;
}
.coefficient {
  color: #007BFF;
}

/* App.css */


.button-bar {
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
}

.button {
  background-color: #333; /* Darker gray for buttons */
  color: white;
  border: 1px solid #666; /* Slight border for definition */
 /* padding: 8px 16px; */
 /*  margin-right: 5px; */
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
}

.button:hover {
  background-color: #555; /* Lighter gray on hover */
  transform: translateY(-2px); /* Slight lift */
}

.blue-text {
  color: #00f; /* Blue text */
  text-decoration: underline; /* Underline text */
  cursor: pointer; /* Pointer to indicate clickability */
  margin-left: 10px; /* Space before text */
}

.chart-section {
  display: none;
  width: 100%;
  min-height: 150px;
 
  border: 1px solid gray; 
  flex: 0 0 auto; 
}
.chart-section1 {
  display: block;
  
}
/* search*/
.search-bar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Remove left and right padding */
  margin-left: 0; /* Ensure no left margin */
  width: 100%;
 
}

.logo-container {
  flex: 0 0 auto;
}



.logo {
  width: 60px;
  height: auto;
}

.search-form {
  display: flex;
  flex: 1 1 auto;
  height: calc(100% - 20px);
  margin-left: 20px;
  margin-right: 20px;
}
{/*.search-form {
  display: flex;
  align-items: center;
  margin: 0 10px;
*/}

.buttons-container {
  display: flex;
  align-items: center;
  margin-left: auto; /* Push the buttons to the right */
}
{/*
{.search-input {
  flex-grow: 1;
  max-width: 400px; 
  padding: 10px;
 
  margin-right: 10px;
  background-color: #555;
  border: none;
  color: #fff;
}
*/}
.search-input {
  flex: 1;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.search-button, .info-button {
  padding: 10px 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: none;
  background-color: #555;
  color: white;
  cursor: pointer;
}

.login-button {
  /* Push the login button to the right */
  padding: 10px 10px;
  background-color: #555;
  border-radius: 5px;
  border: none;
  color: #fff;
  cursor: pointer;
}
.search-button:hover, .info-button:hover {
  background-color: #777; /* Lighter on hover */
}

/* Remove outline on focus for inputs and buttons */
.search-input:focus, .search-button:focus, .info-button:focus {
  outline: none;
}
.sidebar {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  background-color: #333; /* Optional: Add a background color for the sidebar */
  padding: 10px 0; /* Optional: Add padding to the top and bottom */
}
.search-bar-container {
    display: flex;
    align-items: center;
    width: 100%;
    
    justify-content: space-between;
}
{/*.sidebar-icon {
  padding: 5px 10px;
  margin-right: 10px;

  border: none;
  color: #fff;
  cursor: pointer;
*/}
.sidebar-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
 
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  margin-right: 10px;
}
.search-bar-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #444;
  transition: all 0.3s ease;
  width: 100%;
  
}
.sidebar-icon.active {
  /*background-color: #555;*/
  
  color: rgb(0, 140, 255); /* Change to desired active color */
}
.active-button {


  background-color: #555;
  color: white;
}


.chart-section.visible {
  display: block;
}


.icon-bar {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background: #333;
  position: fixed;
  top: 50px;
  width: 100%;
}

.login-modal {
  background: #222;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  margin: 100px auto;
  position: relative;
  z-index: 1000;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.login-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  justify-content: center;
  z-index: 999;
}

.login-modal h2 {
  margin-top: 0;
  font-size: 24px;
  text-align: center;
  color: #fff;
}

.login-modal form {
  display: flex;
  flex-direction: column;
}

.login-modal label {
  margin-bottom: 10px;
  color: #ccc;
}

.login-modal input {
  width: calc(100% - 20px);
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #444;
  background-color: #333;
  color: white;
}

.login-modal button {
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: #555;
  color: white;
  cursor: pointer;
}

.login-modal button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  color: white;
}
.login-modal button[type="submit"] {
  background-color: #28a745;
}
.login-modal button[type="button"] {
  background-color: #dc3545;
}

.login-modal button:hover {
  opacity: 0.8;
}


.label {
  font-weight: bold;
  color: #FFD700; /* Gold color to highlight labels */
}

.info-text span {
  margin-right: 10px;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #1e1e1e;
}

.MuiDrawer-paper {
  border-right: 1px solid #444;
}

.MuiTableCell-head {
  background-color: #333;
  color: white;
}

.MuiTableRow-root:hover {
  background-color: #444;
}


.left-sidebar {
  background-color: #2c2c2c;
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  transition: width 0.3s;
  overflow: hidden;
}

.sidebar-toggle {
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.sidebar-content {
  padding: 15px;
  overflow-y: auto;
  flex-grow: 1;
}

.column-toggle {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.table-container {
  overflow: auto;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.table {
  flex: 1;
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: 8px;
  border: 1px solid #444;
  text-align: left;
}

.table th {
  background-color: #333;
}

.table tbody tr:hover {
  background-color: #444;
}

.MuiTableRow-root {
  width:  10px !important; /* Adjust to make rows shorter */

}

/* 🔹 Modal Styling */
.custom-modal {
  background: white;
  padding: 25px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 90%;
  margin: auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out;
}

.custom-modal-overlay {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 10px;
}

.subscribe-button, .cancel-button {
  flex: 1;
  padding: 12px 15px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

/* 🔹 Primary Button */
.subscribe-button {
  background: #ff9800;
  color: white;
  border: none;
}

.subscribe-button:hover {
  background: #e68900;
  transform: scale(1.05);
  box-shadow: 0px 3px 6px rgba(255, 152, 0, 0.3);
}

/* 🔹 Secondary Button */
.cancel-button {
  background: #ccc;
  color: black;
  border: none;
}

.cancel-button:hover {
  background: #bbb;
  transform: scale(1.05);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

/* 🔹 Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/*              */
/* 🌙 Dark Theme User Profile */
.user-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e1e1e;
  padding: 12px 18px;
  border-radius: 10px;
  
  width: fit-content;
  max-width: 500px;
  gap: 15px;
  overflow: hidden;
}

/* 🔹 User Avatar */
.user-avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 2px solid #ff9800;
  object-fit: cover;
}

/* 🔹 User Info */
.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* 🔹 Welcome Text */
.welcome-text {
  font-size: 14px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
}

/* 🔹 Email Box */
.email-box {
  background: rgba(255, 255, 255, 0.1);
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 12px;
  color: white;
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 🔹 Subscription Status */
.subscription-status {
  font-size: 14px;
  color: #ff9800;
  margin-top: 2px;
  font-weight: bold;
}

/* 🔹 Logout Button */
.logout-button {
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 7px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  font-size: 12px;
}

.logout-button:hover {
  background-color: #cc0000;
}

/* 🔹 Login Section */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

/* 🔹 Google Login Button */
.google-login-button {
  background: white;
  color: black;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
}

.google-login-button:hover {
  background: #f1f1f1;
}

/* 🔹 Google Logo */
.google-logo {
  width: 20px;
  height: 20px;
}

/* 🔹 Responsive Fix */
@media (max-width: 768px) {
  .user-profile {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 350px;
  }

  .email-box {
    max-width: 140px;
  }

  .logout-button {
    font-size: 10px;
    padding: 6px 10px;
  }
}
/* 🔹 Google Login Container */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

/* 🔹 Google Login Button */
.google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: white;
  color: black;
  border: 1px solid gray;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s;
  width: 200px;
}

.google-login-button:hover {
  background: #f1f1f1;
}

/* 🔹 Google Logo Fix */
.google-logo {
  width: 20px;
  height: 20px;
}

