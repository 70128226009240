@tailwind base;
@tailwind components;
@tailwind utilities;
/* 🌙 Dark Theme for Subscription Page */
.subscription-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #121212; /* Dark Background */
    color: white;
    font-family: "Arial", sans-serif;
  }
  
  .subscription-card {
    background: #1e1e1e; /* Dark Card Background */
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    width: 400px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  }
  
  .subscription-card h2 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #ff9800; /* Gold Accent */
  }
  
  .subscription-card p {
    font-size: 16px;
    margin-bottom: 15px;
    opacity: 0.8;
  }
  
  .subscription-benefits {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .subscription-benefits li {
    font-size: 14px;
    padding: 5px;
    border-left: 3px solid #ff9800;
    margin-bottom: 5px;
    text-align: left;
    padding-left: 10px;
  }
  
  .subscription-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .subscribe-button, .go-back-button {
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: 0.3s;
  }
  
  .subscribe-button {
    background-color: #ff9800;
    color: white;
  }
  
  .subscribe-button:hover {
    background-color: #e68a00;
  }
  
  .go-back-button {
    background-color: #555;
    color: white;
  }
  
  .go-back-button:hover {
    background-color: #777;
  }
  