/* 🌙 Dark Theme for Checkout Page */
.checkout-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #121212; /* Dark Background */
    color: white;
    font-family: "Arial", sans-serif;
  }
  
  .checkout-card {
    background: #1e1e1e; /* Dark Card */
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    width: 400px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  }
  
  .checkout-card h2 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #ff9800; /* Gold Accent */
  }
  
  .checkout-card p {
    font-size: 16px;
    margin-bottom: 15px;
    opacity: 0.8;
  }
  
  .price-info {
    background: #2a2a2a;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .price-info h3 {
    color: #ff9800;
    margin: 0;
  }
  
  .price-info p {
    font-size: 18px;
    margin: 5px 0 0;
  }
  
  .confirm-button, .go-back-button {
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: 0.3s;
    width: 100%;
    margin-top: 10px;
  }
  
  .confirm-button {
    background-color: #ff9800;
    color: white;
  }
  
  .confirm-button:hover {
    background-color: #e68a00;
  }
  
  .go-back-button {
    background-color: #555;
    color: white;
  }
  
  .go-back-button:hover {
    background-color: #777;
  }
  