/* Make the container take the full height but prevent global scrolling */
.container {
    display: flex;
    flex-direction: column;
   
    overflow: hidden; /* Prevent page-level scrolling */
    background-color: #1e1e1e; /* Match your theme */
    border-radius: 15px; /* Rounded edges */
}

/* Keep header-section sticky, so only table scrolls */
.header-section {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #1e1e1e;
   
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 15px; /* Rounded edges */
}

/* Ensure only the table scrolls */
.table-container {
    flex-grow: 1;
    overflow-y: auto; /* Only vertical scrolling */
    overflow-x: hidden;
    height: calc(100vh - 150px); /* Dynamic height */
    
}

/* Style the scrollbar to match the dark theme */
/* ✅ Default Scrollbar */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* ✅ Scrollbar Track (Background) */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light Mode */
  }
  .dark ::-webkit-scrollbar-track {
    background: #2c2c2c; /* Dark Mode */
  }
  
  /* ✅ Scrollbar Handle (The draggable part) */
  ::-webkit-scrollbar-thumb {
    background: #b5b5b5; /* Light Mode */
    border-radius: 5px;
  }
  .dark ::-webkit-scrollbar-thumb {
    background: #555; /* Dark Mode */
  }
  
  /* ✅ Scrollbar Handle on Hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
  .dark ::-webkit-scrollbar-thumb:hover {
    background: #777;
  }

/* Scrollbar for the entire page */
html, body {
    overflow: hidden; /* Prevent double scrollbars */
    background-color: #1e1e1e;
}

/* Fix DatePicker overlapping issue */

/* Tabs Styling */
.tabs-container {
    display: flex;
    padding: 0px 0px 0px 5px;
    margin-bottom: 10px;
}

.tab-button {
    padding: 8px 15px;
    background: #3a3a3a;
    color: white;
    border: 1px solid #555;
    border-radius: 5px;
    cursor: pointer;
}

.tab-button.active {
    background: #B0BEC5;
    color: black;
}

/* Table Styling */
.stock-table {
    width: 100%;
    border-collapse: collapse;
}

.stock-table th, .stock-table td {
    border-bottom: 1px solid #444;
    text-align: left;
}

/* Sticky headers inside the table */
.stock-table th {
    background-color: #2c2c2c;
    color: white;
    position: sticky;
    top: 0;
    z-index: 2;
    cursor: pointer;
}

/* Improve table row hover effect */
.stock-table tr:hover {
    background-color: #333;
    cursor: pointer;
}

/* Watchlist button styling */
.watchlist-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px !important;
    transition: transform 0.2s ease;
}

.watchlist-btn.favorited {
    transform: scale(1.2);
}

/* Sorting icon */
.sortable {
    cursor: pointer;
    white-space: nowrap;
}

.sortable:hover {
    background-color: #3a3a3a;
}

.search-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 1px;  /* Fix: Reduce excessive roundness */
    border: none;
    background-color: #2c2c2c;
    color: white;
    outline: none;
    transition: 0.3s;
    display: flex;
    align-items: center;
}

.search-input input {
    border-radius: 0; /* Fix: Remove roundness from search input */
    flex: 1;
    background: transparent;
    color: white;
    padding: 8px;
}

.search-input i {
    padding: 8px;
    color: #aaa;
}
.watchlist-controls{
    padding: 0px 0px 0px 5px;
}

.custom-datepicker {
    background-color: #2c2c2c !important;
    color: white !important;
    border-radius: 5px !important;
    border: 1px solid #555 !important;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

/* Selected Date Styling */
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: #beccd3 !important;
    color: black !important;
}
.custom-datepicker:focus {
    outline: none !important;
    box-shadow: none !important;
}
.react-datepicker {
    @apply bg-white text-black dark:bg-gray-800 dark:text-white border border-gray-300 dark:border-gray-600 rounded-md shadow-lg;
    z-index: 99999;
}
  
  .react-datepicker__header {
    @apply bg-gray-200 dark:bg-gray-200 text-black dark:text-white;
  }
  
  .react-datepicker__day {
    @apply text-black dark:text-white hover:bg-gray-300 dark:hover:bg-gray-600 rounded-md transition;
  }
  
  .react-datepicker__day--selected {
    @apply bg-blue-500 text-white dark:bg-blue-700 dark:text-white;
  }
  
  .react-datepicker__day--disabled {
    @apply text-gray-400 dark:text-gray-500 cursor-not-allowed;
  }
  .table-container {
    margin-top: 0px; /* 🔥 Ensure no top margin */
    padding-top: 0px; /* 🔥 Ensure no top padding */
  }

  